<template>
  <template v-if="initPlayer===false">
    <div class="container">
      <div class="wrapper">
        <button type="button" @click="init">Запустить</button>
      </div>
    </div>
  </template>
  <template v-else>
    <a-scene>
      <a-assets v-if="eventsConfig!==null" timeout="10000">
        <img v-if="eventsConfig.previewImage"
             id="previewImage"
             :src="eventsConfig.previewImage">
        <video id="video"
               crossorigin="anonymous"
               preload="auto"
               autobuffer
               loop="true"
               playsinline
               webkit-playsinline
               timeout="10000"
               v-if="eventsConfig.primaryVideoUrl"
               :src="eventsConfig.primaryVideoUrl">
        </video>
      </a-assets>

      <template v-if="startScreen===true">
        <a-sky v-if="eventsConfig!==null && eventsConfig.previewImage"
               color="#ECECEC"
               src="#previewImage"></a-sky>
        <a-camera>
          <a-entity position="0 0 -1.5"
                    :text="'align:center;\n'+
'                width:6;\n'+
'                wrapCount:100;\n'+
'                color: white;\n'+
'                value: ' + timerMessage ">
          </a-entity>
        </a-camera>
      </template>
      <template v-else>
        <a-videosphere v-if="$nextTick(() => { playVideo()  })"
                       rotation="0 -90 0"
                       src="#video"></a-videosphere>
        <a-camera></a-camera>
      </template>

    </a-scene>
  </template>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      initPlayer: false,
      startScreen: true,
      eventsConfig: null,
      timerMessage: '',
    }
  },
  props: {
    configUrl: String
  },
  methods: {
    init() {
      this.initPlayer = true;
      setInterval(() => {
        this.getEventsConfig();
      }, 1000);
    },
    getEventsConfig() {
      if (this.startScreen === true) {
        fetch(this.$props.configUrl)
            .then(response => response.json())
            .then(json => {
              this.eventsConfig = json;
              if (this.eventsConfig !== null && this.eventsConfig.startVideoTime) {

                //Сообщение о начале
                this.timerMessage = 'Start Video ' + this.formatDate()

                //Проверка на запуск
                if (this.checkTimeStartVideo('<=')) {
                  this.startScreen = false;
                }
              }
            });
      }
    },
    checkTimeStartVideo(eq = '===') {
      let startTime = (new Date(this.eventsConfig.startVideoTime)).getTime();
      let nowDate = (new Date()).getTime();
      let check = new Function('a', 'b', 'return a ' + eq + ' b');
      return check(startTime, nowDate);
    },
    formatDate() {
      if (this.eventsConfig !== null && this.eventsConfig.startVideoTime) {
        let date = new Date(this.eventsConfig.startVideoTime);

        return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`
      }
    },
    playVideo() {
      let video = document.getElementById('video');
      if (video) {
        video.play();
      } else {
        setTimeout(this.playVideo, 500);
      }
    }
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

a-scene {
  margin-top: 90px;
  background-color: black;
}

.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
}

.wrapper {
  align-content: center;
  width: 100%;
}

.wrapper button {
  width: 10rem;
  height: 3rem;
  border-radius: 5px;
  background-color: blue;
  color: white;
  font-weight: 900;
}

.wrapper button:hover {
  background-color: lime;
  color: black;
}
</style>