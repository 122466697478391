import {createApp} from 'vue'
import App from './App.vue'
import 'aframe';
import 'aframe-extras';

createApp(
    App,
    {
        configUrl: document.getElementById('app').dataset.configUrl
    }
)
    .mount('#app')
